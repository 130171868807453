import { Injectable } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import { ApiUrlConstant } from '../../apiUrl.constant';
const update_student_profile_url = AppComponent.API_URL + 'mobile/UpdateStudentProfile';
const update_parent_contact_information_url = AppComponent.API_URL + 'selfRegistration/AddSelfRegisteredPlayerSecondaryContacts';
const getTimeLineUrl = AppComponent.API_URL + 'player/GetPlayerLTADDailyActivities';

@Injectable()
export class StudentListService {
  player_id: string = '';
  player_details: any;
  payment_details: any;
  deregister_player_detail: any;
  deregister_player_details: any;
  player_new_group_details: any;
  add_player_final_submit: any;
  get_batch_details: any;
  secondary_contact_details: any;
  contact_details: any;
  tag_list: any;
  add_tag_list: any;
  httpOptions;
  temp_date: any;
  date_format: any;
  update_student: any;
  timeline_data: any;
  player_competition_tracker_details: any;
  invoice_update_status: any;
  history_deregister_id: any;
  academy_center_id: string;
  card_details: any;
  notification_details: any;
  user_details: any;
  constructor(private http_client: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
  }


  getPlayerList(flag, organization_id) {
    const params = new HttpParams().set('flag', flag)
      .set('organization_id', organization_id);

    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['center_player_data'].length != 0) {
            return data['center_player_data'];
          }
          else {
            return '';
          }
        }),
      )
  }
  getOrganizationPlayerList(flag, organization_id, filter_value, filter_type, age_flag, tags_ids) {

    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('flag', flag);

    for (var i = 0; i < filter_type.length; i++) {
      if (filter_type[i] == "Gender") {
        if (filter_value[i]['Gender'] != undefined) {
          params = params.append('filters[gender]', filter_value[i]['Gender']);
        }

      }
      else if (filter_type[i] == "Age") {
        if (filter_value[i]['Age'][0] != null && filter_value[i]['Age'][1] != null) {
          params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
          params = params.append('filters[age][before]', filter_value[i]['Age'][1]);
        }
        else if (age_flag == "Y") {
          if (filter_value[i]['Age'][0] != undefined) {
            params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
          }
        }
        else if (age_flag == "N") {
          if (filter_value[i]['Age'][0] != undefined) {
            params = params.append('filters[age][before]', filter_value[i]['Age'][0]);
          }
        }
      }
      else if (filter_type[i] == "Training_From_Date") {
        if (filter_value[i]['Training_From_Date'] != undefined) {
          params = params.append('filters[training_date][from_date]', this.dateFormater(filter_value[i]['Training_From_Date']));
        }
      }
      else if (filter_type[i] == "Training_To_Date") {
        if (filter_value[i]['Training_To_Date'] != undefined) {
          params = params.append('filters[training_date][to_date]', this.dateFormater(filter_value[i]['Training_To_Date']));
        }
      }
      else if (filter_type[i] == "Group") {
        for (var j = 0; j < filter_value[i]['Group'].length; j++) {
          params = params.append('filters[group]' + '[' + j + ']', filter_value[i]['Group'][j]);
        }
      }
      else if (filter_type[i] == "Academy") {
        for (var j = 0; j < filter_value[i]['Academy'].length; j++) {
          params = params.append('filters[organization]' + '[' + j + ']', filter_value[i]['Academy'][j]);
        }
      }
      else if (filter_type[i] == "PaymentDue") {
        if (filter_value[i]['PaymentDue'] != undefined) {
          params = params.append('filters[payment_due][flag]', filter_value[i]['PaymentDue']);
        }
      }
      else if (filter_type[i] == "FeePlan") {
        for (var j = 0; j < filter_value[i]['FeePlan'].length; j++) {
          params = params.append('filters[fees_plan]' + '[' + j + ']', filter_value[i]['FeePlan'][j]);
        }
      }

    }
    if (tags_ids != undefined) {
      tags_ids.forEach((element, i) => {
        params = params.append('filters[tag]' + '[' + i + ']', element);
      });
    }

    const local_playerlist_url = AppComponent.API_URL + 'player/GetOrganizationPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data['data']['players'];
          }
          else {
            return '';
          }
        }),
      )
  }

  getSubmittedPlayerList(flag, organization_id, player_status) {
    const params = new HttpParams().set('flag', flag)
      .set('player_status', player_status)
      .set('organization_id', organization_id);

    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['center_player_data'].length != 0) {
            return data['center_player_data'];
          }
          else {
            return '';
          }
        }),
      )
  }
  getSubmittedParentOrgPlayerList(flag, organization_id, player_status, filter_value,
    filter_type) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('flag', flag);
    params = params.append('player_status', player_status);
    for (var i = 0; i < filter_type.length; i++) {
      if (filter_type[i] == "Academy") {
        for (var j = 0; j < filter_value[i]['Academy'].length; j++) {
          params = params.append('filters[organization]' + '[' + j + ']', filter_value[i]['Academy'][j]);
        }
      }
    }

    const local_playerlist_url = AppComponent.API_URL + 'player/GetOrganizationPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPlayerListWithFilter(flag, organization_id, filter_value, filter_type, age_flag, tags_ids) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('flag', flag);

    for (var i = 0; i < filter_type.length; i++) {
      if (filter_type[i] == "Gender") {
        if (filter_value[i]['Gender'] != undefined) {
          params = params.append('filters[gender]', filter_value[i]['Gender']);
        }

      }
      else if (filter_type[i] == "Age") {
        if (filter_value[i]['Age'][0] != null && filter_value[i]['Age'][1] != null) {
          params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
          params = params.append('filters[age][before]', filter_value[i]['Age'][1]);
        }
        else if (age_flag == "Y") {
          if (filter_value[i]['Age'][0] != undefined) {
            params = params.append('filters[age][after]', filter_value[i]['Age'][0]);
          }
        }
        else if (age_flag == "N") {
          if (filter_value[i]['Age'][0] != undefined) {
            params = params.append('filters[age][before]', filter_value[i]['Age'][0]);
          }
        }
      }
      else if (filter_type[i] == "Training_From_Date") {
        if (filter_value[i]['Training_From_Date'] != undefined) {
          params = params.append('filters[training_date][from_date]', this.dateFormater(filter_value[i]['Training_From_Date']));
        }
      }
      else if (filter_type[i] == "Training_To_Date") {
        if (filter_value[i]['Training_To_Date'] != undefined) {
          params = params.append('filters[training_date][to_date]', this.dateFormater(filter_value[i]['Training_To_Date']));
        }
      }
      else if (filter_type[i] == "Group") {
        for (var j = 0; j < filter_value[i]['Group'].length; j++) {
          params = params.append('filters[group]' + '[' + j + ']', filter_value[i]['Group'][j]);
        }
      }
      else if (filter_type[i] == "PaymentDue") {
        if (filter_value[i]['PaymentDue'] != undefined) {
          params = params.append('filters[payment_due][flag]', filter_value[i]['PaymentDue']);
        }
      }
      else if (filter_type[i] == "FeePlan") {
        for (var j = 0; j < filter_value[i]['FeePlan'].length; j++) {
          params = params.append('filters[fees_plan]' + '[' + j + ']', filter_value[i]['FeePlan'][j]);
        }
      }

    }
    if (tags_ids != undefined) {
      tags_ids.forEach((element, i) => {
        params = params.append('filters[tag]' + '[' + i + ']', element);
      });
    }
    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['center_player_data'].length != 0) {
            return data['center_player_data'];
          }
          else {
            return '';
          }
        }),
      )
  }

  addBulkPlayers(player_data, organization_id, login_customer_id, login_role_id) {
    //var body = player_data;
    let params = new HttpParams();
    // alert(filter_value['Group']);
    params = params.append('player_data', player_data);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);
    const local_addbulkplayers_url = AppComponent.API_URL + 'webadmin/AddBulkPlayerDataValidation';
    return this.http_client.post(local_addbulkplayers_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['add_bulk_player_data_validation'].length != 0) {
            return data['add_bulk_player_data_validation'];
          }
          else {
            return '';
          }
        }),
      )
  }

  BulkPlayersUpload(player_data, organization_id, login_customer_id, login_role_id, academy_center_id,
    file_name, date_format, upload_parent_details_flag, upload_guradian_details_flag) {
    //var body = player_data;
    let params = new HttpParams();
    // alert(filter_value['Group']);
    params = params.append('player_data', player_data);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('file_name', file_name);
    params = params.append('date_format', date_format);
    params = params.append('upload_parent_details_flag', upload_parent_details_flag);
    params = params.append('upload_guradian_details_flag', upload_guradian_details_flag);
    const local_addbulkplayers_url = AppComponent.API_URL + 'web_bulkupload/UploadBulkPlayerData';
    return this.http_client.post(local_addbulkplayers_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data != null) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getUploadFileDetail(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);
    const url = AppComponent.API_URL + 'web_bulkupload/GetOrganizationPlayerBulkUploadFilesDetails';

    return this.http_client.post(url, params, this.httpOptions).pipe(map(response => {

      if (response != null) {
        return response;
      } else {
        return '';
      }
    }))
  }

  resolveforeignkeyForBulkUpload(player_data, organization_id, login_customer_id, login_role_id) {
    // var body = player_data;
    let params = new HttpParams();
    params = params.append('player_data', player_data);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);
    const local_addbulkplayers_url = AppComponent.API_URL + 'webadmin/GetForeignKeyDetails';
    return this.http_client.post(local_addbulkplayers_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['get_foreign_key_details'].length != 0) {
            return data['get_foreign_key_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  addsingleplayerattendance(player_id, organization_id, academy_center_id, logged_in_customer_id, form) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('logged_in_customer_id', logged_in_customer_id);

    var hour, minute, second;
    hour = form.attendance_time['hour'];
    minute = form.attendance_time['minute'];
    second = form.attendance_time['second'];
    var amPM = (hour > 11) ? "PM" : "AM";
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    let final_time = hour + ":" + minute + ":" + second;
    var punch_in_time = this.dateFormater(form.attendance_date) + " " + final_time;

    params = params.append('punch_in_time', punch_in_time);

    const local_singleattendance_url = AppComponent.API_URL + 'attendance/AddSingleAttendance';
    return this.http_client.post(local_singleattendance_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )

  }


  getPlayerPaymentHistory(player_id, academy_center_id, organization_id) {
    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    const local_player_payment_history_url = AppComponent.API_URL + 'mobile/GetPlayerPaymentHistory';
    return this.http_client.post(local_player_payment_history_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.payment_details = data['player_payment_details'];
          if (this.payment_details != '0') {
            if (this.payment_details.length != 0) {
              return this.payment_details;
            }
            else {
              return '';
            }

          }
          else {
            return false;
          }
        }),
      )
  }

  acceptPlayerPayment(invoice_id, payment_date, payment_method, transaction_amount,
    cheque_no, transaction_id, writeoff_reason, form, membership_no) {

    let params = new HttpParams();
    params = params.append('invoice_id', invoice_id);
    params = params.append('payment_date', this.dateFormater(payment_date));
    params = params.append('payment_method', payment_method);
    if (payment_method == 'Cheque') {
      params = params.append('cheque_no', cheque_no);
    }
    if (payment_method == 'Bank Transfer' || payment_method == 'Card Swipe(POS)') {
      params = params.append('payment_id', transaction_id);
    }
    if (payment_method == 'Write-Off') {
      params = params.append('writeoff_reason', writeoff_reason);
    }
    if (payment_method == 'Debit To Member') {
      params = params.append('membership_no', membership_no);
    }
    params = params.append('transaction_amount', form.partialPaymentFlag ? form.transaction_amount : transaction_amount);
    const local_player_payment_accept_url = AppComponent.API_URL + 'payment/AcceptPlayerPayment';
    return this.http_client.post(local_player_payment_accept_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPlayerFeeSetup(academy_id, academy_center_id, player_id) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    const local_player_fee_setup_url = AppComponent.API_URL + 'mobile/GetEditPlayerFeesDetails';
    return this.http_client.post(local_player_fee_setup_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Edit Player Fees details'].length != 0) {
            return data['Edit Player Fees details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  UpdatePlayerFeesDetails(organization_id, player_id, academy_center_id, plan_id, frequency,
    payment_type, invoice_amount, student_fee_id, payment_due_date,existing_plan_id, customer_id,
    action, subscription_expiry_date, days, subscription_reason, subscription_resumes_from_date,
    renewal_criteria, no_of_sessions,training_start_date?,session_start_date?,post_paid?) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('plan_id', plan_id);
    params = params.append('frequency', frequency);
    params = params.append('payment_type', payment_type);
    if(invoice_amount!=undefined){
      params = params.append('invoice_amount', invoice_amount);
    }
    params = params.append('student_fee_id', student_fee_id);
    params = params.append('existing_plan_id', existing_plan_id);
    params = params.append('customer_id', customer_id);

    if (payment_type == 'Free') {
      params = params.append('payment_due_date', payment_due_date);
    }
    if (payment_type == 'Onetime') {
      var payment_due = this.dateFormater(payment_due_date);
      params = params.append('payment_due_date', payment_due);
    }
    if (payment_type == 'Recurring') {
      var payment_due = this.dateFormater(payment_due_date);
      params = params.append('payment_due_date', payment_due);
    }
    if (payment_type == 'Installments') {
      var payment_due = this.dateFormater(payment_due_date);
      params = params.append('payment_due_date', payment_due);
    }
    if (payment_type == 'Subscription') {

      if (action == 'Extend' || action == 'Pause') {
        params = params.append('payment_due_date', payment_due_date);
      }
      if (action != 'Extend' && action != 'Pause' && action == 'Renew') {
        var payment_due = this.dateFormater(payment_due_date);
        params = params.append('payment_due_date', payment_due);
      }
      if (action == undefined) {
        var payment_due = this.dateFormater(payment_due_date);
        params = params.append('payment_due_date', payment_due);
      }
      if(session_start_date!=undefined){
        params = params.append('training_start_date',this.dateFormater(session_start_date));
        params = params.append('session_start_date',this.dateFormater(session_start_date));
        params=params.append('post_paid',post_paid);
      }
      
    }


    if (action == 'Extend') {
      params = params.append('action', action);
      params = params.append('subscription_expiry_date', subscription_expiry_date);
      params = params.append('days', days);
      params = params.append('subscription_reason', subscription_reason);
      // params =params.append('payment_due_date', payment_due_date);
    }
    if (action == 'Pause') {
      params = params.append('action', action);
      params = params.append('subscription_expiry_date', subscription_expiry_date);
      params = params.append('days', days);
      params = params.append('subscription_resumes_from_date', subscription_resumes_from_date);
      params = params.append('subscription_reason', subscription_reason);
      // params =params.append('payment_due_date', payment_due_date);
    }
    if (action == 'Renew') {
      params = params.append('action', action);
      params = params.append('currency', sessionStorage.getItem('currency'));
      params = params.append('renewal_criteria', renewal_criteria);
      params = params.append('no_of_sessions', no_of_sessions);
    }

    const local_player_fee_setup_url = AppComponent.API_URL + 'payment/UpdatePlayerFeesDetails';
    return this.http_client.post(local_player_fee_setup_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getGroupDetailsByOrg(organization_id) {

    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    const local_grouplist_url = AppComponent.API_URL + 'groups/GetGroupDetailsByOrg';
    return this.http_client.post(local_grouplist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['group_details'].length != 0) {
            return data['group_details'];
          }
          else {
            return '';
          }
        }),
      )
  }
  finalBulkPlayerImport(player_data, organization_id, login_customer_id, login_role_id) {

    let params = new HttpParams();
    params = params.append('player_data', player_data);
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);
    const local_final_addbulkplayers_url = AppComponent.API_URL + 'webadmin/ImportBulkPlayerTempTable';
    return this.http_client.post(local_final_addbulkplayers_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['import_bulk_player_details'].length != 0) {
            return data['import_bulk_player_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  GetPlayerDeRegisterFormDetails(player_id, academy_center_id, organization_id) {

    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);
    const getPlayer_deregistration_formDetails_url = AppComponent.API_URL + 'player/GetPlayerDeRegisterFormDetails';
    return this.http_client.post(getPlayer_deregistration_formDetails_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['deregister_form_details'].length != 0) {
            return data['deregister_form_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  deRegisterPlayer(player_id, academy_center_id, organization_id, form, customer_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);
    params = params.append('deregister_date', this.dateFormater(form.deregistration_effective_date));
    params = params.append('deregister_reason', form.deregistration_reason);
    params = params.append('login_user_id', customer_id);
    const deregister_player_details_url = AppComponent.API_URL + 'player/DeRegisterPlayer';
    return this.http_client.post(deregister_player_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['deregister_state'].length != 0) {
            return data['deregister_state'];
          }
          else {
            return '';
          }
        }),
      )
  }
  GetPlayerTrainingDays(player_id, academy_center_id) {

    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    const get_Trainig_Days_url = AppComponent.API_URL + 'player/GetPlayerTrainingDays';
    return this.http_client.post(get_Trainig_Days_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Player Training Days'].length != 0) {
            return data['Player Training Days'];
          }
          else {
            return '';
          }
        }),
      )
  }

  UpdatePlayerTrainingDays(academy_center_id, player_id, training_days_id, monday, tuesday, wednesday,
    thursday, friday, saturday, sunday) {
    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    params = params.append('training_days_id', training_days_id);
    params = params.append('monday', monday == true ? '1' : '0');
    params = params.append('tuesday', tuesday == true ? '1' : '0');
    params = params.append('wednesday', wednesday == true ? '1' : '0');
    params = params.append('thursday', thursday == true ? '1' : '0');
    params = params.append('friday', friday == true ? '1' : '0');
    params = params.append('saturday', saturday == true ? '1' : '0');
    params = params.append('sunday', sunday == true ? '1' : '0');
    const update_player_traingDays_url = AppComponent.API_URL + 'player/UpdatePlayerTrainingDays';
    return this.http_client.post(update_player_traingDays_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Training Days State'].length != 0) {
            return data['Training Days State'];
          }
          else {
            return '';
          }
        }),
      )
  }

  GetEditPlayerGroupFormData(player_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);

    const getPlayer_group_url = AppComponent.API_URL + 'mobile/GetEditPlayerGroupFormData';
    return this.http_client.post(getPlayer_group_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player_group_form_Data'].length != 0) {
            return data['player_group_form_Data'];
          }
          else {
            return '';
          }
        }),
      )
  }

  UpdatePlayerGroup(player_id, academy_center_id, academy_group_id, organization_id, allowUpdate, scoring_delete_flag,
    old_academy_group_id) {

    let params = new HttpParams();
    params = params.append('player_id[0]', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('academy_group_id', academy_group_id);
    params = params.append('organization_id', organization_id);
    params = params.append('allowUpdate', allowUpdate);
    params = params.append('scoring_delete_flag', scoring_delete_flag);
    params = params.append('old_academy_group_id', old_academy_group_id);

    const update_player_Groups_url = AppComponent.API_URL + 'player/UpdatePlayerGroup';
    return this.http_client.post(update_player_Groups_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.player_new_group_details = data['player_group_details'];
          if (this.player_new_group_details['allowUpdate'] == "Y") {

            this.UpdatePlayerGroup(player_id, academy_center_id, academy_group_id, organization_id, allowUpdate = "Y", scoring_delete_flag, old_academy_group_id);

          }

          else if (this.player_new_group_details['allowUpdate'] == "N") {

            this.UpdatePlayerGroup(player_id, academy_center_id, academy_group_id, organization_id, allowUpdate, scoring_delete_flag = "N", old_academy_group_id);

          }
          else if (this.player_new_group_details['allowUpdate'] != "Y" && this.player_new_group_details['allowUpdate'] != "N" && this.player_new_group_details.length != 0) {
            return this.player_new_group_details;
          }
          else {
            return '';
          }
        }),
      )
  }

  GetKitDetails() {
    const get_Kit_details_url = AppComponent.API_URL + 'player/GetKitDetails';
    return this.http_client.post(get_Kit_details_url, [], this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Kit Details'].length != 0) {
            return data['Kit Details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  GetPlayerKitDetails(player_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);

    const getPlayerKitDetails_url = AppComponent.API_URL + 'player/GetPlayerKitDetails';
    return this.http_client.post(getPlayerKitDetails_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player_kits_details'].length != 0) {
            return data['player_kits_details'];
          }
          else {
            return '';
          }
        }),
      )
  }
  GetAcademyAndPlayerKitDetails(player_id, academy_center_id, organization_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);

    const getAcademyAndPlayerKitDetails_url = AppComponent.API_URL + 'mobile/GetAcademyAndPlayerKitDetails';
    return this.http_client.post(getAcademyAndPlayerKitDetails_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['kit_details'].length != 0) {
            return data['kit_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  AddPlayerForm2Data(academy_center_id, player_id, organization_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);

    const getAcademyAndPlayerKitDetails_url = AppComponent.API_URL + 'mobile/GetAcademyAndPlayerKitDetails';
    return this.http_client.post(getAcademyAndPlayerKitDetails_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['kit_details'].length != 0) {
            return data['kit_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  //data for Add player form 2

  SendAddPlayerForm2Data(academy_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('academy_center_id', academy_center_id);

    const get_form2_details_url = AppComponent.API_URL + 'mobile/SendAddPlayerForm2Data';
    return this.http_client.post(get_form2_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Send Add Player form 2 Data State'].length != 0) {
            return data['Send Add Player form 2 Data State'];
          }
          else {
            return '';
          }
        }),
      )

  }
  addPlayerDetails(organization_id,
    player_image, thumbnail_image, emailExistFlag, customer_id, player_id, form, tab_text_label) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id[0]', form.academy_center_id);
    params = params.append('academy_group_id', form.register_student_academy_group_id);
    params = params.append('first_name', form.first_name);
    params = params.append('last_name', form.last_name);
    params = params.append('gender', form.gender);
    params = params.append('date_of_birth', this.dateFormater(form.date_of_birth));
    params = params.append('address_line1', form.address_line1);
    if (tab_text_label == 'Submitted') {
      params = params.append('from', 'submitted');
    }
    if (emailExistFlag == "Y") {
      params = params.append('emailExistFlag', emailExistFlag);
      params = params.append('customer_id', customer_id);
      params = params.append('player_id', player_id);
    }
    if (form.email_address != '' && form.email_address != null && form.email_address != undefined) {
      params = params.append('email_address', form.email_address);
    }
    if (form.mobile_number != '' && form.mobile_number != null && form.mobile_number != undefined) {
      params = params.append('mobile_number', form.mobile_number);
      if (form.country_code != '' && form.country_code != null && form.country_code != undefined) {
        params = params.append('country_code', form.country_code);
      }
    }
    if (form.school != '' && form.school != null) {
      params = params.append('school', form.school);
    }
    if (form.medical_condition != '' && form.medical_condition != null) {
      params = params.append('medical_condition', form.medical_condition);
    }
    if (form.dominant_side != '' && form.dominant_side != null) {
      params = params.append('dominant_side', form.dominant_side);
    }
    if (form.blood_group != '' && form.blood_group != null) {
      params = params.append('blood_group', form.blood_group);
    }
    if (player_image != undefined) {
      params = params.append('player_image', player_image);
      params = params.append('thumbnail_image', thumbnail_image);
    }
    if (form.country_id != '' && form.country_id != null) {
      params = params.append('country_id', form.country_id);
      params = params.append('state_id', form.state_id);
      params = params.append('city_id', form.city_id);
    }
    if (form.zipcode != '' && form.zipcode != null) {
      params = params.append('zipcode', form.zipcode);
    }

    if (form.academyid != '' && form.academyid != null) {
      params = params.append('uin', form.academyid);
    }

    if (this.history_deregister_id != '' && this.history_deregister_id != null && this.history_deregister_id != undefined) {
      params = params.append('history_deregister_id', this.history_deregister_id);
    }


    const addPlayer_form1_details_url = AppComponent.API_URL + 'player/AddPlayerDetails';
    return this.http_client.post(addPlayer_form1_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }
  //add player Form 1 submit
  AddPlayerDetails(academy_center_id, first_name, last_name, email_address, gender, date_of_birth,
    blood_group, country_code, mobile_number, dominant_side, school, medical_condition, organization_id,
    player_image, thumbnail_image, emailExistFlag, customer_id, player_id, academy_group_id,
    country_id, state_id, city_id, address_line1, zipcode, uin) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id[0]', academy_center_id);
    params = params.append('academy_group_id', academy_group_id);
    params = params.append('first_name', first_name);
    params = params.append('last_name', last_name);
    params = params.append('gender', gender);
    params = params.append('date_of_birth', date_of_birth);
    params = params.append('address_line1', address_line1);

    if (emailExistFlag == "Y") {
      params = params.append('emailExistFlag', emailExistFlag);
      params = params.append('customer_id', customer_id);
      params = params.append('player_id', player_id);
    }
    if (email_address != '' && email_address != null && email_address != undefined) {
      params = params.append('email_address', email_address);
    }
    if (mobile_number != '' && mobile_number != null && mobile_number != undefined) {
      params = params.append('mobile_number', mobile_number);
      if (country_code != '' && country_code != null && country_code != undefined) {
        params = params.append('country_code', country_code);
      }
    }
    if (school != '' && school != null) {
      params = params.append('school', school);
    }
    if (medical_condition != '' && medical_condition != null) {
      params = params.append('medical_condition', medical_condition);
    }
    if (dominant_side != '' && dominant_side != null) {
      params = params.append('dominant_side', dominant_side);
    }
    if (blood_group != '' && blood_group != null) {
      params = params.append('blood_group', blood_group);
    }
    if (player_image != undefined) {
      params = params.append('player_image', player_image);
      params = params.append('thumbnail_image', thumbnail_image);
    }
    if (country_id != '' && country_id != null) {
      params = params.append('country_id', country_id);
      params = params.append('state_id', state_id);
      params = params.append('city_id', city_id);
    }
    if (zipcode != '' && zipcode != null) {
      params = params.append('zipcode', zipcode);
    }

    if (uin != '' && uin != null) {
      params = params.append('uin', uin);
    }

    if (this.history_deregister_id != '' && this.history_deregister_id != null && this.history_deregister_id != undefined) {
      params = params.append('history_deregister_id', this.history_deregister_id);
    }
    const addPlayer_form1_details_url = AppComponent.API_URL + 'player/AddPlayerDetails';
    return this.http_client.post(addPlayer_form1_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  //Add player final submit
  isValidDate(d) {
    return d instanceof Date;
  }

  addPlayer_parent_info_Submit(academy_center_id, player_id, organization_id, Customer_id,
    player_customer_id, register_student_form2, parents_contact_form, academy_group_id) {

    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('player_customer_id', player_customer_id);
    params = params.append('academy_group_id', academy_group_id);
    params = params.append('fee_plan_flag', 'N');
    if (Customer_id != undefined) {
      params = params.append('customer_id', Customer_id);
    }

    if (register_student_form2.Kit_Details_select != undefined) {
      params = params.append('kits_size', register_student_form2.Kit_Details_select);
    }
    var flag = false;

    if (parents_contact_form.father_first_name != null) {
      params = params.append('father[first_name]', parents_contact_form.father_first_name);
      flag = true;
    }
    if (parents_contact_form.father_last_name != null) {
      params = params.append('father[last_name]', parents_contact_form.father_last_name);
      flag = true;
    }
    if (parents_contact_form.father_email != null) {
      params = params.append('father[email_address]', parents_contact_form.father_email);
      flag = true;
    }
    if (parents_contact_form.father_mobile != null) {
      params = params.append('father[mobile_number]', parents_contact_form.father_mobile);
      if (parents_contact_form.father_mobile_country_code != null) {
        params = params.append('father[country_code]', parents_contact_form.father_mobile_country_code);
      }
      flag = true;
    }
    if (parents_contact_form.father_occupation != null) {
      params = params.append('father[occupation]', parents_contact_form.father_occupation);
      flag = true;
    }
    if (parents_contact_form.mother_first_name != null) {
      params = params.append('mother[first_name]', parents_contact_form.mother_first_name);
      flag = true;
    }
    if (parents_contact_form.mother_last_name != null) {
      params = params.append('mother[last_name]', parents_contact_form.mother_last_name);
      flag = true;
    }
    if (parents_contact_form.mother_email != null) {
      params = params.append('mother[email_address]', parents_contact_form.mother_email);
      flag = true;
    }
    if (parents_contact_form.mother_mobile != null) {
      params = params.append('mother[mobile_number]', parents_contact_form.mother_mobile);
      if (parents_contact_form.mother_mobile_country_code != null) {
        params = params.append('mother[country_code]', parents_contact_form.mother_mobile_country_code);
      }
      flag = true;
    }
    if (parents_contact_form.mother_occupation != null) {
      params = params.append('mother[occupation]', parents_contact_form.mother_occupation);
      flag = true;
    }

    if (flag == true) {
      params = params.append('parent_info_flag', "Y");
    }
    else {
      params = params.append('parent_info_flag', "N");
    }

    const addPlayer_finalSubmit_url = AppComponent.API_URL + 'mobile/AddPlayerForm2Data';
    return this.http_client.post(addPlayer_finalSubmit_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Add Player form 2 Data State'].length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  AddPlayer_final_Submit(academy_center_id, player_id, organization_id, Customer_id,
    player_customer_id, register_student_form2, parents_contact_form, parent_info_flag, academy_group_id, from_reregistration_in_same_center?) {

    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);

    params = params.append('player_customer_id', player_customer_id);
    params = params.append('academy_group_id', academy_group_id);
    if (from_reregistration_in_same_center != undefined) {
      params = params.append('from_reregistration_in_same_center', from_reregistration_in_same_center);
    }

    if (Customer_id != undefined) {
      params = params.append('customer_id', Customer_id);
    }

    if (register_student_form2.Kit_Details_select != undefined) {
      params = params.append('kits_size', register_student_form2.Kit_Details_select);
    }
    var flag = false;

    if (parents_contact_form.father_first_name != null) {
      params = params.append('father[first_name]', parents_contact_form.father_first_name);
      flag = true;
    }
    if (parents_contact_form.father_last_name != null) {
      params = params.append('father[last_name]', parents_contact_form.father_last_name);
      flag = true;
    }
    if (parents_contact_form.father_email != null) {
      params = params.append('father[email_address]', parents_contact_form.father_email);
      flag = true;
    }
    if (parents_contact_form.father_mobile != null) {
      params = params.append('father[mobile_number]', parents_contact_form.father_mobile);
      if (parents_contact_form.father_mobile_country_code != null) {
        params = params.append('father[country_code]', parents_contact_form.father_mobile_country_code);
      }
      flag = true;
    }
    if (parents_contact_form.father_occupation != null) {
      params = params.append('father[occupation]', parents_contact_form.father_occupation);
      flag = true;
    }
    if (parents_contact_form.mother_first_name != null) {
      params = params.append('mother[first_name]', parents_contact_form.mother_first_name);
      flag = true;
    }
    if (parents_contact_form.mother_last_name != null) {
      params = params.append('mother[last_name]', parents_contact_form.mother_last_name);
      flag = true;
    }
    if (parents_contact_form.mother_email != null) {
      params = params.append('mother[email_address]', parents_contact_form.mother_email);
      flag = true;
    }
    if (parents_contact_form.mother_mobile != null) {
      params = params.append('mother[mobile_number]', parents_contact_form.mother_mobile);
      if (parents_contact_form.mother_mobile_country_code != null) {
        params = params.append('mother[country_code]', parents_contact_form.mother_mobile_country_code);
      }
      flag = true;
    }
    if (parents_contact_form.mother_occupation != null) {
      params = params.append('mother[occupation]', parents_contact_form.mother_occupation);
      flag = true;
    }

    if (flag == true) {
      params = params.append('parent_info_flag', "Y");
    }
    else {
      params = params.append('parent_info_flag', "N");
    }
    let count: number = 0;
    let no: number = 0;
    params = params.append('fee_plan_flag', 'Y');
    register_student_form2['fees_type'].forEach((element, i) => {

      if (element.fee_type == 'Onetime') {
        params = params.append('oneTime_payment_type[' + count + ']', element.fee_type);
        params = params.append('oneTime_plan_id[' + count + ']', element.oneTime_plan_id);
        params = params.append('oneTime_payment_due_date[' + count + ']', this.dateFormater(element.oneTime_payment_due_date));
        params = params.append('oneTime_frequency[' + count + ']', element.oneTime_frequency);
        params = params.append('onetime_invoice_amount[' + count + ']', element.onetime_invoice_amount);

        //  console.log(this.isValidDate(element.training_start_date))       
        params = params.append('oneTime_training_start_date[' + count + ']', (this.isValidDate(element.training_start_date) ? this.dateFormater(element.training_start_date) : element.training_start_date));
        count++;
      }
      if (element.fee_type == 'Recurring') {

        params = params.append('plan_id', element.recurring_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', element.recurring_frequency);
        params = params.append('invoice_amount', element.recurring_invoice_amount);
        params = params.append('training_start_date', this.dateFormater(element.recurring_training_start_date));
        params = params.append('payment_due_date', this.dateFormater(element.recurring_payment_due_date));
      }
      if (element.fee_type == 'Free') {

        var day = new Date();
        params = params.append('plan_id', element.free_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', 'NA');
        params = params.append('training_start_date', this.dateFormater(day));
        params = params.append('payment_due_date', this.dateFormater(day));
      }
      if (element.fee_type == 'Installments') {

        params = params.append('plan_id', element.installments_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', 'NA');
        params = params.append('invoice_amount', element.installments_fee_amount);
        params = params.append('training_start_date', this.dateFormater(element.installments_training_start_date));
        params = params.append('payment_due_date', this.dateFormater(element.installments_payment_due_date));
      }
      if (element.fee_type == 'Subscription') {
        params = params.append('subsription_plan_id[' + no + ']', element.subscription_plan_id);
        params = params.append('subsription_frequency[' + no + ']', element.subscription_frequency);
        params = params.append('subsription_payment_type[' + no + ']', element.fee_type);
        params = params.append('subsription_invoice_amount[' + no + ']', element.subscription_invoice_amount);
        if(element.subscription_training_start_date!=undefined){
          params = params.append('subsription_training_start_date[' + no + ']', this.dateFormater(element.subscription_training_start_date));
        }
        if(element.subscription_session_start_date!=undefined){
          params = params.append('subsription_training_start_date[' + no + ']', this.dateFormater(element.subscription_session_start_date));
          params=params.append('subscription_session_start_date[' + no + ']', this.dateFormater(element.subscription_session_start_date));
        }  
        params = params.append('subsription_payment_due_date[' + no + ']', this.dateFormater(element.subscription_payment_due_date));
        
        no++;

      }
    });


    const addPlayer_finalSubmit_url = AppComponent.API_URL + 'mobile/AddPlayerForm2Data';
    return this.http_client.post(addPlayer_finalSubmit_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['Add Player form 2 Data State'].length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  //player autocomplete
  Searchplayer(search_string, organization_id) {
    let params = new HttpParams();
    params = params.append('search_string', search_string);
    params = params.append('organization_id', organization_id);

    const search_player_url = AppComponent.API_URL + 'player/Searchplayer';
    return this.http_client.post(search_player_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player_data'].length != 0) {
            return data['player_data'];
          }
          else {
            return '';
          }
        }),
      )

  }

  getPlayerDetails(player_id, organization_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);

    const get_player_details_url = AppComponent.API_URL + 'player/GetPlayerDetails';
    return this.http_client.post(get_player_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player_data'].length != 0) {
            return data['player_data'];
          }
          else {
            return '';
          }
        }),
      )

  }

  updatePlayerDetails(player_id, academy_center_id, uin, blood_group, dominant_side, school, medical_condition, organization_id, customer_id, player_image, thumbnail_image, edit_img_flag) {

    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id[0]', academy_center_id);
    params = params.append('uin', uin);
    params = params.append('blood_group', blood_group);
    params = params.append('dominant_side', dominant_side);
    params = params.append('school', school);
    params = params.append('medical_condition', medical_condition);
    params = params.append('organization_id', organization_id);
    params = params.append('customer_id', customer_id);
    if (edit_img_flag == true) {
      if (player_image != undefined && thumbnail_image != undefined) {
        params = params.append('player_image', player_image);
        params = params.append('thumbnail_image', thumbnail_image);
      }
    }


    const update_player_details_url = AppComponent.API_URL + 'player/UpdatePlayerDetails';
    return this.http_client.post(update_player_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getMonthlyAttendanceView(academy_center_id, organization_id, player_id, input_date) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);
    params = params.append('input_date', input_date);
    const getMonthlyAttendance_url = AppComponent.API_URL + 'attendance/GetMonthlyAttendanceView';
    return this.http_client.post(getMonthlyAttendance_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['monthly_attendance_details'].length != 0) {
            return data['monthly_attendance_details'];
          }
          else {
            return '';
          }
        }),
      )

  }

  GetBatchDetails(organization_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('filters[center]', academy_center_id);


    const state_list_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['batch'].length != 0) {
            return data['batch'];
          }
          else {
            return '';
          }
        }),
      )
  }

  GetBatchDetail(organization_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);

    const state_list_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.get_batch_details = data['batch'];
          if (this.get_batch_details.length != 0) {
            return this.get_batch_details;
          }
          else {
            return '';
          }
        }),
      )
  }
  getOrganizationCenterBatches(organization_id, filter_value, filter_type, child_academies) {
    let params = new HttpParams();
    params = params.append('parent_organization_id', organization_id);
    let flag = false;
    filter_type.forEach((element, i) => {
      if (element == "Academy") {
        for (var j = 0; j < filter_value[i]['Academy'].length; j++) {
          params = params.append('filters[organization_id]' + '[' + j + ']', filter_value[i]['Academy'][j]);
        }
        flag = true;
      }
    });
    if (!flag) {
      child_academies.forEach((element, i) => {
        params = params.append('filters[organization_id]' + '[' + i + ']', element['academy_child_id']);
      });
    }

    const state_list_url = AppComponent.API_URL + 'batch/GetOrganizationCenterBatches';
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  GetPlayerSecondaryContacts(player_id, organization_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);

    const secondary_contact_list_url = AppComponent.API_URL + 'player/GetPlayerSecondaryContacts';
    return this.http_client.post(secondary_contact_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player_secondary_contact_details'].length != 0) {
            return data['player_secondary_contact_details'];
          }
          else {
            return '';
          }
        }),
      )

  }

  AddPlayerSecondaryContacts(player_id, organization_id, father_firstname, father_lastname,
    father_email, father_mobile, father_occupation, mother_firstname, mother_lastname, mother_email,
    mother_mobile, mother_occupation) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('father[first_name]', father_firstname);
    params = params.append('father[last_name]', father_lastname);
    params = params.append('father[email_address]', father_email);
    params = params.append('father[mobile_number]', father_mobile);
    params = params.append('father[occupation]', father_occupation);
    params = params.append('mother[first_name]', mother_firstname);
    params = params.append('mother[last_name]', mother_lastname);
    params = params.append('mother[email_address]', mother_email);
    params = params.append('mother[mobile_number]', mother_mobile);
    params = params.append('mother[occupation]', mother_occupation);


    const secondary_contact_list_url = AppComponent.API_URL + 'player/AddPlayerSecondaryContacts';
    return this.http_client.post(secondary_contact_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.contact_details = data['player_id'];
          return this.contact_details;
        })
      );

  }

  UpdateNextPaymentDueDate(academy_center_id, player_id, plan_id, organization_id, next_payment_due_date, next_payment_delay_count, reason) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('plan_id', plan_id);
    params = params.append('organization_id', organization_id);
    params = params.append('next_payment_due_date', next_payment_due_date);
    params = params.append('next_payment_delay_count', next_payment_delay_count);

    if (reason != "") {
      params = params.append('reason', reason);
    }
    const url = AppComponent.API_URL + 'payment/UpdateNextPaymentDueDate';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data['status'];
        })
      );

  }

  addPlayerFeesSetup(organization_id, academy_center_id, Player_id, customer_id, payment_due_date, add_feeplan_form,
    plan_id, payment_type, frequency, invoice_amount) {

    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', Player_id);
    params = params.append('customer_id', customer_id);

    if (payment_type == "Recurring" || payment_type == "Installments" || payment_type == "Free") {
      params = params.append('payment_type', payment_type);
      params = params.append('plan_id', plan_id);
      params = params.append('invoice_amount', invoice_amount);

      if (payment_type == "Recurring") {
        params = params.append('frequency', frequency);
        var payment_due = this.dateFormater(add_feeplan_form.recurring_payment_due_date);
        params = params.append('payment_due_date', payment_due);
        params = params.append('training_start_date', this.dateFormater(add_feeplan_form.recurring_training_start_date));
      }
      if (payment_type == "Installments") {
        params = params.append('frequency', 'NA');
        var payment_due = this.dateFormater(add_feeplan_form.installment_payment_due_date);
        params = params.append('payment_due_date', payment_due);
        params = params.append('training_start_date', this.dateFormater(add_feeplan_form.installments_training_start_date));
      }
      if (payment_type == "Free") {
        params = params.append('frequency', 'NA');
        var current_date = new Date();
        params = params.append('payment_due_date', this.dateFormater(current_date));
        params = params.append('training_start_date', this.dateFormater(current_date));
      }
    }
    if (payment_type == "Onetime") {
      params = params.append('oneTime_payment_type[0]', payment_type);
      params = params.append('oneTime_plan_id[0]', plan_id);
      params = params.append('onetime_invoice_amount[0]', invoice_amount);
      var payment_due = this.dateFormater(add_feeplan_form.oneTime_payment_due_date);
      params = params.append('oneTime_payment_due_date[0]', payment_due);
      params = params.append('oneTime_training_start_date[0]', this.dateFormater(add_feeplan_form.training_start_date));
      params = params.append('oneTime_frequency[0]', frequency);
    }
    if (payment_type == "Subscription") {
      
      params = params.append('subsription_payment_type[0]', payment_type);
      params = params.append('subsription_plan_id[0]', plan_id);
      if(invoice_amount!=undefined){
        params = params.append('subsription_invoice_amount[0]', invoice_amount);
      }
      var payment_due = this.dateFormater(add_feeplan_form.subscription_payment_due_date);
      params = params.append('subsription_payment_due_date[0]', payment_due);
      if(add_feeplan_form.subscription_training_start_date!=undefined){
      params = params.append('subsription_training_start_date[0]', this.dateFormater(add_feeplan_form.subscription_training_start_date));
      }
      params = params.append('subsription_frequency[0]', frequency);
      if(add_feeplan_form.subscription_session_start_date!=undefined){
        params = params.append('subscription_session_start_date[0]', this.dateFormater(add_feeplan_form.subscription_session_start_date));
        params = params.append('subsription_training_start_date[0]', this.dateFormater(add_feeplan_form.subscription_session_start_date));
      }
      
    }
    const url = AppComponent.API_URL + 'player/AddPlayerFeesSetup';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );

  }

  getTagList(academy_id, tag_name) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    // if(tag_name!='')
    // {
    //   params = params.append('tag_name', tag_name);
    // }  
    const local_getTagList_url = AppComponent.API_URL + 'tag/GetTagListByTagName';
    return this.http_client.post(local_getTagList_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['tag'].length != 0) {
            return data['tag'];
          }
          else {
            return '';
          }
        }),
      )
  }

  getTagsDetailsByPlayer(player_ids, academy_id) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    player_ids.forEach((item, index) => {
      params = params.append('player_ids[' + index + ']', item);
    });
    const local_getTagdetails_url = AppComponent.API_URL + 'tag/GetTagsDetailsByPlayer';
    return this.http_client.post(local_getTagdetails_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['player'].length != 0) {
            return data['player'];
          }
          else {
            return '';
          }
        }),
      )
  }

  deletePlayerTag(customer_id, academy_id, player_id, tag_id) {
    let params = new HttpParams();
    params = params.append('customer_id', customer_id);
    params = params.append('academy_id', academy_id);
    params = params.append('player_id', player_id);
    params = params.append('tag_id', tag_id);


    const url = AppComponent.API_URL + 'tag/DeletePlayerTag';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['delete_player_tag']) {
            return data['delete_player_tag'];
          }
          else {
            return 'false';
          }
        }),
      )
  }

  addTagsDetails(customer_id, tag, academy_id, player_id, batch_ids) {
    let params = new HttpParams();
    params = params.append('customer_id', customer_id);
    params = params.append('academy_id', academy_id);
    tag.forEach((element, i) => {
      params = params.append('tag[' + i + '][tag_id]', element['tag_id']);
      params = params.append('tag[' + i + '][tag_name]', element['tag_name']);
    });
    params = params.append('player[0][player_id]', player_id);
    params = params.append('player[0][academy_group_id]', batch_ids);

    // player_id.forEach((item, index) => {
    //   params = params.append('player['+index+'][player_id]',item);  
    // }); 
    // batch_ids.forEach((item, index) => {
    //   params = params.append('player['+index+'][academy_group_id]',item);
    // }); 
    const local_addTagList_url = AppComponent.API_URL + 'tag/AddPlayersTagsDetails';
    return this.http_client.post(local_addTagList_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.add_tag_list = data['player_tag'];
          if (this.add_tag_list != '') {
            return this.add_tag_list;
          }
          else {
            return '';
          }
        }),
      )
  }

  dateFormater(date, format?) {

    if (format) {
      this.date_format = format;
    }
    else {
      this.date_format = sessionStorage.getItem('date_format');
    }
    var day1 = new Date(date);
    var month: any;
    var day;
    day = day1.getDate();
    month = (day1.getMonth() + 1);
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (this.date_format == "Y-m-d") {
      return this.temp_date = day1.getFullYear() + "-" + month + "-" + day;
    }
    if (this.date_format == "d-m-Y") {
      return this.temp_date = day + "-" + month + "-" + day1.getFullYear();
    }
    if (this.date_format == "Y/m/d") {
      return this.temp_date = day1.getFullYear() + "/" + month + "/" + day;
    }

    if (this.date_format == "d/m/Y") {
      return this.temp_date = day + "/" + month + "/" + day1.getFullYear();
    }

    if (this.date_format == "m/d/Y") {
      return this.temp_date = day + "/" + month + "/" + day1.getFullYear();
    }


  }
  getDeregisterPlayerDetail(organization_id, player_id, academy_center_id) {
    const params = new HttpParams()
      .set('organization_id', organization_id)
      .set('academy_center_id', academy_center_id)
      .set('player_id', player_id);

    const url = AppComponent.API_URL + 'player/GetDeregisteredPlayersDetails';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.deregister_player_detail = data;
          if (this.deregister_player_detail['status']) {
            return this.deregister_player_detail;
          }
          else {
            return '';
          }
        }),
      )
  }

  updateStudentProfile(organization_id, player_id, edit_player, action_flag, player_image, add_info_flag) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('customer_id', edit_player['customer_id']);
    params = params.append('academy_center_id[0]', edit_player['academy_center_id']);
    params = params.append('first_name', edit_player['first_name']);
    params = params.append('last_name', edit_player['last_name']);
    params = params.append('gender', edit_player['gender']);
    params = params.append('date_of_birth', this.dateFormater(edit_player['date_of_birth']));
    params = params.append('academy_group_id', edit_player['academy_group_id']);
    params = params.append('address_line1', edit_player['address_line1']);
    if (action_flag != '' && action_flag != null && action_flag != undefined) {
      params = params.append('action_flag', action_flag);
    }

    if (edit_player['email_address'] != '' && edit_player['email_address'] != null && edit_player['email_address'] != undefined) {
      params = params.append('email_address', edit_player['email_address']);
    }
    if (edit_player['mobile_number'] != '' && edit_player['mobile_number'] != null && edit_player['mobile_number'] != undefined) {
      params = params.append('mobile_number', edit_player['mobile_number']);
      if (edit_player['country_code'] != '' && edit_player['country_code'] != null && edit_player['country_code'] != undefined) {
        params = params.append('country_code', edit_player['country_code']);
      }

    }
    if (edit_player['uin'] != '' && edit_player['uin'] != null) {
      params = params.append('uin', edit_player['uin']);
    }
    if (add_info_flag == '1') {
      if (edit_player['school'] != '' && edit_player['school'] != null) {
        params = params.append('school', edit_player['school']);
      }
      if (edit_player['medical_condition'] != '' && edit_player['medical_condition'] != null) {
        params = params.append('medical_condition', edit_player['medical_condition']);
      }
      if (edit_player['dominant_side'] != '' && edit_player['dominant_side'] != null) {
        params = params.append('dominant_side', edit_player['dominant_side']);
      }
      if (edit_player['blood_group'] != '' && edit_player['blood_group'] != null) {
        params = params.append('blood_group', encodeURIComponent(edit_player['blood_group']));
      }
    }

    if (player_image != undefined) {
      params = params.append('player_image', player_image);
      params = params.append('thumbnail_image', player_image);
    }

    if (edit_player['country_id'] != '' && edit_player['country_id'] != null) {
      params = params.append('country_id', edit_player['country_id']);
      params = params.append('state_id', edit_player['state_id']);
      params = params.append('city_id', edit_player['city_id']);
    }
    if (edit_player['zipcode'] != '' && edit_player['zipcode'] != null) {
      params = params.append('zipcode', edit_player['zipcode']);
    }

    return this.http_client.post(update_student_profile_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          this.update_student = data['Update Student State'];
          if (this.update_student.length != 0) {
            return this.update_student;
          }
          else {
            return '';
          }
        }),
      )
  }
  updateFatherContactInformation(organization_id, player_id, father_information) {

    let params = new HttpParams();

    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);



    if (father_information.father_firstname != null && father_information.father_firstname != '') {
      params = params.append('father[first_name]', father_information['father_firstname']);
    }

    if (father_information.father_lastname != null && father_information.father_lastname != '') {
      params = params.append('father[last_name]', father_information['father_lastname']);
    }

    if (father_information.father_email != null && father_information.father_email != '') {
      params = params.append('father[email_address]', father_information['father_email']);
    }

    if (father_information.country_code != null && father_information.country_code != '') {
      params = params.append('father[country_code]', father_information['country_code']);
    }

    if (father_information.father_mobile != null && father_information.father_mobile != '') {
      params = params.append('father[mobile_number]', father_information['father_mobile']);
    }

    if (father_information.father_occupation != null && father_information.father_occupation != '') {
      params = params.append('father[occupation]', father_information['father_occupation']);
    }


    return this.http_client.post(update_parent_contact_information_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          // console.log(data);


          return data;

        }),
      )
  }
  updateMotherContactInformation(organization_id, player_id, mother_information) {

    let params = new HttpParams();

    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);



    if (mother_information.mother_firstname != null && mother_information.mother_firstname != '') {
      params = params.append('mother[first_name]', mother_information['mother_firstname']);
    }

    if (mother_information.mother_lastname != null && mother_information.mother_lastname != '') {
      params = params.append('mother[last_name]', mother_information['mother_lastname']);
    }

    if (mother_information.mother_email != null && mother_information.mother_email != '') {
      params = params.append('mother[email_address]', mother_information['mother_email']);
    }

    if (mother_information.country_code != null && mother_information.country_code != '') {
      params = params.append('mother[country_code]', mother_information['country_code']);
    }

    if (mother_information.mother_mobile != null && mother_information.mother_mobile != '') {
      params = params.append('mother[mobile_number]', mother_information['mother_mobile']);
    }

    if (mother_information.mother_occupation != null && mother_information.mother_occupation != '') {
      params = params.append('mother[occupation]', mother_information['mother_occupation']);
    }


    return this.http_client.post(update_parent_contact_information_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          return data;
        }),
      )
  }
  getPlayerLTADActivities(player_id, organization_id, start_count, from_date, to_date) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('start_count', start_count);
    params = params.append('from_date', this.dateFormater(from_date));
    params = params.append('to_date', this.dateFormater(to_date));

    return this.http_client.post(getTimeLineUrl, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          this.timeline_data = data['daily_activities_details'];
          if (this.timeline_data['player_details'].length != 0) {
            return this.timeline_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  activatePlayer(academy_center_id, player_id, organization_id,
    customer_id, player_customer_id, register_student_form2, display_fee_plan_page) {


    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('customer_id', customer_id);
    params = params.append('player_customer_id', player_customer_id);
    params = params.append('academy_group_id', register_student_form2.register_student_academy_group_id);
    params = params.append('player_status', '1');
    if (display_fee_plan_page != true) {
      params = params.append('fee_plan_flag', 'N');
    } else {
      params = params.append('fee_plan_flag', 'Y');
    }

    if (register_student_form2.academyid != null) {
      params = params.append('uin', register_student_form2.academyid);
    }
    let count: number = 0;
    let no: number = 0;
    register_student_form2['fees_type'].forEach((element, i) => {

      if (element.fee_type == 'Onetime') {
        params = params.append('oneTime_payment_type[' + count + ']', element.fee_type);
        params = params.append('oneTime_plan_id[' + count + ']', element.oneTime_plan_id);
        params = params.append('oneTime_payment_due_date[' + count + ']', this.dateFormater(element.oneTime_payment_due_date));
        params = params.append('oneTime_frequency[' + count + ']', element.oneTime_frequency);
        params = params.append('onetime_invoice_amount[' + count + ']', element.onetime_invoice_amount);
        params = params.append('oneTime_training_start_date[' + count + ']', this.dateFormater(element.training_start_date));
        count++;
      }
      if (element.fee_type == 'Recurring') {
        params = params.append('plan_id', element.recurring_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', element.recurring_frequency);
        params = params.append('invoice_amount', element.recurring_invoice_amount);
        params = params.append('training_start_date', this.dateFormater(element.recurring_training_start_date));
        params = params.append('payment_due_date', this.dateFormater(element.recurring_payment_due_date));
      }
      if (element.fee_type == 'Free') {
        var day = new Date();
        params = params.append('plan_id', element.free_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', 'NA');
        params = params.append('training_start_date', this.dateFormater(day));
        params = params.append('payment_due_date', this.dateFormater(day));
      }
      if (element.fee_type == 'Installments') {
        params = params.append('plan_id', element.installments_plan_id);
        params = params.append('payment_type', element.fee_type);
        params = params.append('frequency', 'NA');
        params = params.append('invoice_amount', element.installments_fee_amount);
        params = params.append('training_start_date', this.dateFormater(element.installments_training_start_date));
        params = params.append('payment_due_date', this.dateFormater(element.installments_payment_due_date));
      }
      if (element.fee_type == 'Subscription') {
        params = params.append('subsription_plan_id[' + no + ']', element.subscription_plan_id);
        params = params.append('subsription_frequency[' + no + ']', element.subscription_frequency);
        params = params.append('subsription_payment_type[' + no + ']', element.fee_type);
        params = params.append('subsription_invoice_amount[' + no + ']', element.subscription_invoice_amount);
        if(element.subscription_training_start_date!=undefined){
          params = params.append('subsription_training_start_date[' + no + ']', this.dateFormater(element.subscription_training_start_date));
        }
        
        params = params.append('subsription_payment_due_date[' + no + ']', this.dateFormater(element.subscription_payment_due_date));
        if(element.subscription_session_start_date!=undefined){
          params = params.append('subsription_training_start_date[' + no + ']', this.dateFormater(element.subscription_session_start_date));
          params=params.append('subscription_session_start_date[' + no + ']', this.dateFormater(element.subscription_session_start_date));
        }   
      }
    });


    const addPlayer_finalSubmit_url = AppComponent.API_URL + 'mobile/AddPlayerForm2Data';
    return this.http_client.post(addPlayer_finalSubmit_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.add_player_final_submit = data['Add Player form 2 Data State'];
          if (this.add_player_final_submit.length != 0) {
            return this.add_player_final_submit;
          }
          else {
            return '';
          }
        }),
      )
  }
  deletePlayer(player_id, academy_center_id, organization_id,
    deregister_date, deregister_reason) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('organization_id', organization_id);
    // params =params.append('deregister_date', this.dateFormater(deregister_date)!);
    // params =params.append('deregister_reason', deregister_reason);
    // const deregister_player_details_url = AppComponent.API_URL + 'player/DeletePlayer'; 
    const deregister_player_details_url = AppComponent.API_URL + 'player/DeleteOrganizationPlayer';

    return this.http_client.post(deregister_player_details_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      );
  }
  getPlayerProfileDetails(player_id, organization_id, academy_center_id,from_date?,to_date?) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('filters[from_date]', this.dateFormater(from_date));
    params = params.append('filters[to_date]', this.dateFormater(to_date));
    const url = AppComponent.API_URL + 'player/GetPlayerProfileDetails';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          // if(data.status==true)
          // {         
          return data;
          // }
          // else
          //   {
          //     return '';
          //   }
        }),
      );
  }
  getCompetitionTracker(player_id, from_date, to_date, organization_id, competition_level, sport_id, competition_id,
    event_name, category_name, sub_category_name, position, competition_medal) {
    let params = new HttpParams();
    params = params.append('player_id[0]', player_id);
    params = params.append('filters[from_date]', this.dateFormater(from_date));
    params = params.append('filters[to_date]', this.dateFormater(to_date));
    params = params.append('organization_id', organization_id);


    if (competition_level != undefined) {
      competition_level.forEach((element, i) => {
        params = params.append('filters[competition_level][' + i + ']', element);
      });
    }
    if (sport_id != undefined) {
      sport_id.forEach((element, i) => {
        params = params.append('filters[sport_id][' + i + ']', element);

      });
    }
    if (event_name != undefined) {
      event_name.forEach((element, i) => {
        params = params.append('filters[event_name][' + i + ']', element);
      });
    }
    if (category_name != undefined) {
      category_name.forEach((element, i) => {
        params = params.append('filters[category_name][' + i + ']', element);
      });
    }
    if (sub_category_name != undefined) {
      sub_category_name.forEach((element, i) => {
        params = params.append('filters[sub_category_name][' + i + ']', element);
      });
    }
    if (competition_id != undefined) {
      competition_id.forEach((element, i) => {
        params = params.append('filters[competition_id][' + i + ']', element);
      });
    }

    if (position != undefined) {
      position.forEach((element, i) => {
        params = params.append('filters[position][' + i + ']', element);
      });
    }
    if (competition_medal != undefined) {
      competition_medal.forEach((element, i) => {
        params = params.append('filters[competition_medal][' + i + ']', element);
      });
    }



    const local_playerlist_url = AppComponent.API_URL + 'report/GetCompetitionTracker';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status']) {
            this.player_competition_tracker_details = data['player_competition_tracker_details'];
            return this.player_competition_tracker_details;
          }
          else {
            return '';
          }
        }),
      )
  }
  getPlayerRankingSummaryDetails(academy_id, player_id, from_date, to_date) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('player_id', player_id);
    params = params.append('filters[from_date]', this.dateFormater(from_date));
    params = params.append('filters[to_date]', this.dateFormater(to_date));


    const local_playerlist_url = AppComponent.API_URL + 'ranking_tracker/GetPlayerRankingSummaryDetails';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == true) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getCountriesCode() {
    const local_playerlist_url = AppComponent.API_URL + 'general/GetCountries';
    return this.http_client.post(local_playerlist_url, '', this.httpOptions)
      .pipe(map((data: any) => {
        if (data['countries_list']) {

          return data;
        }
        else {
          return '';
        }
      }),
      )
  }
  generateSubscriptionDates(action, days, organization_id, student_fee_id) {
    let params = new HttpParams();
    params = params.append('action', action);
    params = params.append('days', days);
    params = params.append('organization_id', organization_id);
    params = params.append('student_fee_id', student_fee_id);
    const url = AppComponent.API_URL + 'payment/GenerateSubscriptionDates';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        }),
      );
  }

  unmarkAttendance(player_id, organization_id, academy_center_id, logged_in_customer_id, attendance_date) {
    const params = new HttpParams().set('customer_id', player_id)
      .set('organization_id', organization_id)
      .set('academy_center_id', academy_center_id)
      .set('attendance_date', attendance_date)
      .set('logged_in_customer_id', logged_in_customer_id);

    const local_singleattendance_url = AppComponent.API_URL + 'attendance/UnsetSingleAttendance';
    return this.http_client.post(local_singleattendance_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )

  }

  getCompetitionsGraphDetails(player_id, from_date, to_date, organization_id, competition_level, sport_id, competition_id,
    event_name, category_name, sub_category_name, position, competition_medal) {

    let params = new HttpParams();
    params = params.append('player_id[0]', player_id);
    params = params.append('from_date', this.dateFormater(from_date));
    params = params.append('to_date', this.dateFormater(to_date));
    params = params.append('organization_id', organization_id);


    if (competition_level != undefined) {
      competition_level.forEach((element, i) => {
        params = params.append('filters[competition_level][' + i + ']', element);
      });
    }
    if (sport_id != undefined) {
      sport_id.forEach((element, i) => {

        params = params.append('filters[sport_id][' + i + ']', element);

      });
    }
    if (event_name != undefined) {
      event_name.forEach((element, i) => {
        params = params.append('filters[event_name][' + i + ']', element);
      });
    }
    if (category_name != undefined) {
      category_name.forEach((element, i) => {
        params = params.append('filters[category_name][' + i + ']', element);
      });
    }
    if (sub_category_name != undefined) {
      sub_category_name.forEach((element, i) => {
        params = params.append('filters[sub_category_name][' + i + ']', element);
      });
    }
    if (competition_id != undefined) {
      competition_id.forEach((element, i) => {
        params = params.append('filters[competition_id][' + i + ']', element);
      });
    }

    if (position != undefined) {
      position.forEach((element, i) => {
        params = params.append('filters[position][' + i + ']', element);
      });
    }
    if (competition_medal != undefined) {
      competition_medal.forEach((element, i) => {
        params = params.append('filters[competition_medal][' + i + ']', element);
      });
    }


    const local_playerlist_url = AppComponent.API_URL + 'competition_tracker/GetCompetitionsGraphDetails';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status']) {
            return data['competition_competition_graph_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  updateInvoiceDate(organization_id, customer_id, player_ids, center_ids, form) {
    let params = new HttpParams();
    for (var i = 0; i < player_ids.length; i++) {
      params = params.append('player_id[' + i + ']', player_ids[i]);
      params = params.append('center_id[' + i + ']', center_ids[i]);
    }
    params = params.append('organization_id', organization_id);
    params = params.append('customer_id', customer_id);
    params = params.append('next_payment_delay_count', form.bulk_next_payment_delay_count);
    if (form.reason != "") {
      params = params.append('reason', form.reason != undefined ? form.reason : '');
    }
    const local_updateplayerinvoicedate_url = AppComponent.API_URL + 'player/UpdatePlayerInvoiceDate';
    return this.http_client.post(local_updateplayerinvoicedate_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          this.invoice_update_status = data.status;
          if (this.invoice_update_status == 'success') {
            return this.invoice_update_status;
          }
          else {
            return '';
          }
        }),
      )
  }

  bulkDeRegisterPlayer(organization_id, customer_id, player_ids, center_ids, form) {
    let params = new HttpParams();
    for (var i = 0; i < player_ids.length; i++) {
      params = params.append('player_id[' + i + ']', player_ids[i]);
      params = params.append('academy_center_id[' + i + ']', center_ids[i]);
    }
    params = params.append('organization_id', organization_id);
    params = params.append('logged_in_customer_id', customer_id);
    params = params.append('deregister_date', this.dateFormater(form.deregister_date));
    if (form.deregister_reason != "") {
      params = params.append('deregister_reason', form.deregister_reason != undefined ? form.deregister_reason : '');
    }
    const local_updateplayerinvoicedate_url = AppComponent.API_URL + 'player/BulkDeRegisterPlayer';
    return this.http_client.post(local_updateplayerinvoicedate_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }


  revertWriteoff(invoice_id) {
    let params = new HttpParams();
    params = params.append('invoice_id', invoice_id);

    const local_playerlist_url = AppComponent.API_URL + 'payment/RevertWriteoff';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['revert_player_payment']) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  receiptDownload(invoice_id) {
    let params = new HttpParams();
    params = params.append('invoice_id', invoice_id);

    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const local_playerlist_url = AppComponent.API_URL + 'payment/ReceiptDownload';
    return this.http_client.post(local_playerlist_url, params, httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        }),
      )
  }

  getRankingLabelAndRatingLabelAndCategory(organization_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);

    const local_playerlist_url = AppComponent.API_URL + 'player_rating/GetRankingLabelAndRatingLabelAndCategory';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  setPlayerOverallRating(organization_id, player_id, login_user_id, overallRating, notes, rating_id, player_rating_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('login_user_id', login_user_id);
    params = params.append('ranking_label_id', overallRating.ranking_label_id);
    params = params.append('notes', notes);

    overallRating['rating'].forEach(element => {
      if (element.rating_id == rating_id) {
        params = params.append('rating_id', element.rating_id);
        params = params.append('rating', element.rating);
      }
    });

    if (player_rating_id != '') { params = params.append('player_rating_id', player_rating_id); }
    const local_playerlist_url = AppComponent.API_URL + 'player_rating/SetPlayerOverallRating';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }
  sendPaymentLink(organization_id, player_id, academy_center_id, payment_link) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('payment_link', payment_link);


    const local_playerlist_url = AppComponent.API_URL + 'payment/SendPaymentLink';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }
  createNewPaymentLink(organization_id, player_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);


    const local_playerlist_url = AppComponent.API_URL + 'payment/CreateNewPaymentLink';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  getDisplayFeePlanFlag(academy_center_id) {
    let params = new HttpParams();
    params = params.append('academy_center_id', academy_center_id);
    const local_playerlist_url = AppComponent.API_URL + 'player/GetDisplayFeePlanFlagDetails';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  transferSinglePlayer(organization_id, player_details, center_id, batch_id, register_student_form2, child_academy_id) {

    let params = new HttpParams();
    params = params.append('from_organization_id', organization_id);
    params = params.append('from_academy_center_id', player_details.academy_center_id);
    params = params.append('player_id', player_details.player_id);
    params = params.append('customer_id', player_details.customer_id);
    params = params.append('to_academy_center_id', center_id);
    params = params.append('to_academy_group_id', batch_id);

    params = params.append('to_organization_id', child_academy_id == '' ? organization_id : child_academy_id);
    let count: number = 0;
    let no: number = 0;
    var date_format = sessionStorage.getItem('date_format_for_player_transfer');
    register_student_form2['fees_type'].forEach((element, i) => {

      if (element.fee_type == 'Onetime') {
        params = params.append('fee_plan_details[oneTime_payment_type][' + count + ']', element.fee_type);
        params = params.append('fee_plan_details[oneTime_plan_id][' + count + ']', element.oneTime_plan_id);
        params = params.append('fee_plan_details[oneTime_payment_due_date][' + count + ']', this.dateFormater(element.oneTime_payment_due_date, date_format));
        params = params.append('fee_plan_details[oneTime_frequency][' + count + ']', element.oneTime_frequency);
        params = params.append('fee_plan_details[onetime_invoice_amount][' + count + ']', element.onetime_invoice_amount);

        //  console.log(this.isValidDate(element.training_start_date))       
        params = params.append('fee_plan_details[oneTime_training_start_date][' + count + ']', (this.isValidDate(element.training_start_date) ? this.dateFormater(element.training_start_date, date_format) : element.training_start_date));
        count++;
      }
      if (element.fee_type == 'Recurring') {

        params = params.append('fee_plan_details[plan_id]', element.recurring_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', element.recurring_frequency);
        params = params.append('fee_plan_details[invoice_amount]', element.recurring_invoice_amount);
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(element.recurring_training_start_date, date_format));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(element.recurring_payment_due_date, date_format));
      }
      if (element.fee_type == 'Free') {

        var day = new Date();
        params = params.append('fee_plan_details[plan_id]', element.free_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', 'NA');
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(day, date_format));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(day, date_format));
      }
      if (element.fee_type == 'Installments') {

        params = params.append('fee_plan_details[plan_id]', element.installments_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', 'NA');
        params = params.append('fee_plan_details[invoice_amount]', element.installments_fee_amount);
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(element.installments_training_start_date, date_format));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(element.installments_payment_due_date, date_format));
      }
      if (element.fee_type == 'Subscription') {
        params = params.append('fee_plan_details[subsription_plan_id][' + no + ']', element.subscription_plan_id);
        params = params.append('fee_plan_details[subsription_frequency][' + no + ']', element.subscription_frequency);
        params = params.append('fee_plan_details[subsription_payment_type][' + no + ']', element.fee_type);
        params = params.append('fee_plan_details[subsription_invoice_amount][' + no + ']', element.subscription_invoice_amount);
        params = params.append('fee_plan_details[subsription_training_start_date][' + no + ']', this.dateFormater(element.subscription_training_start_date, date_format));
        params = params.append('fee_plan_details[subsription_payment_due_date][' + no + ']', this.dateFormater(element.subscription_payment_due_date, date_format));
        no++;

      }
    });
    const local_playerlist_url = AppComponent.API_URL + 'player/TransferSinglePlayer';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }
  bulktransferPlayer(organization_id, center_id, batch_id, register_student_form2, child_academy_id, players) {

    let params = new HttpParams();
    params = params.append('from_organization_id', organization_id);
    params = params.append('to_academy_center_id', center_id);
    params = params.append('to_academy_group_id', batch_id);
    params = params.append('to_organization_id', child_academy_id == '' ? organization_id : child_academy_id);

    players.forEach((element, i) => {
      params = params.append('players[' + i + '][from_academy_center_id]', element.academy_center_id);
      params = params.append('players[' + i + '][player_id]', element.player_id);
      params = params.append('players[' + i + '][customer_id]', element.customer_id);
    });

    let count: number = 0;
    let no: number = 0;
    register_student_form2['fees_type'].forEach((element, i) => {

      if (element.fee_type == 'Onetime') {
        params = params.append('fee_plan_details[oneTime_payment_type][' + count + ']', element.fee_type);
        params = params.append('fee_plan_details[oneTime_plan_id][' + count + ']', element.oneTime_plan_id);
        params = params.append('fee_plan_details[oneTime_payment_due_date][' + count + ']', this.dateFormater(element.oneTime_payment_due_date));
        params = params.append('fee_plan_details[oneTime_frequency][' + count + ']', element.oneTime_frequency);
        params = params.append('fee_plan_details[onetime_invoice_amount][' + count + ']', element.onetime_invoice_amount);

        //  console.log(this.isValidDate(element.training_start_date))       
        params = params.append('fee_plan_details[oneTime_training_start_date][' + count + ']', (this.isValidDate(element.training_start_date) ? this.dateFormater(element.training_start_date) : element.training_start_date));
        count++;
      }
      if (element.fee_type == 'Recurring') {

        params = params.append('fee_plan_details[plan_id]', element.recurring_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', element.recurring_frequency);
        params = params.append('fee_plan_details[invoice_amount]', element.recurring_invoice_amount);
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(element.recurring_training_start_date));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(element.recurring_payment_due_date));
      }
      if (element.fee_type == 'Free') {

        var day = new Date();
        params = params.append('fee_plan_details[plan_id]', element.free_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', 'NA');
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(day));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(day));
      }
      if (element.fee_type == 'Installments') {

        params = params.append('fee_plan_details[plan_id]', element.installments_plan_id);
        params = params.append('fee_plan_details[payment_type]', element.fee_type);
        params = params.append('fee_plan_details[frequency]', 'NA');
        params = params.append('fee_plan_details[invoice_amount]', element.installments_fee_amount);
        params = params.append('fee_plan_details[training_start_date]', this.dateFormater(element.installments_training_start_date));
        params = params.append('fee_plan_details[payment_due_date]', this.dateFormater(element.installments_payment_due_date));
      }
      if (element.fee_type == 'Subscription') {
        params = params.append('fee_plan_details[subsription_plan_id][' + no + ']', element.subscription_plan_id);
        params = params.append('fee_plan_details[subsription_frequency][' + no + ']', element.subscription_frequency);
        params = params.append('fee_plan_details[subsription_payment_type][' + no + ']', element.fee_type);
        params = params.append('fee_plan_details[subsription_invoice_amount][' + no + ']', element.subscription_invoice_amount);
        params = params.append('fee_plan_details[subsription_training_start_date][' + no + ']', this.dateFormater(element.subscription_training_start_date));
        params = params.append('fee_plan_details[subsription_payment_due_date][' + no + ']', this.dateFormater(element.subscription_payment_due_date));
        no++;

      }
    });
    const local_playerlist_url = AppComponent.API_URL + 'player/TransferPlayer';
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  getPlayerTransferHistory(player_id, organization_id, academy_center_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    const url = AppComponent.API_URL + 'player/GetPlayerTransferHistory';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => { return data; }),
      );
  }

  GetAttendanceReport(organization_id, academy_center_id, from_date, to_date, user_type, customer_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('customer_id', customer_id);
    params = params.append('academy_center_id', academy_center_id);
    // if(academy_center_id!=undefined){
    // academy_center_id.forEach((element,i) => {
    //   params = params.append('academy_center_id' + '[' + i + ']', element);
    // }); }
    if (user_type == 'staff') {
      params = params.append('is_staff', 'Y');
    } else {
      params = params.append('is_staff', 'N');
    }
    if (from_date != undefined && from_date != '') {
      params = params.append('from', this.dateFormater(from_date)!);
    }
    if (to_date != undefined && to_date != '') {
      params = params.append('to', this.dateFormater(to_date)!);
    }

    const deregister_bulk_player_url = AppComponent.API_URL + 'dashboard/GetAttendanceReport';
    return this.http_client.post(deregister_bulk_player_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['attendance_report_details'].length != 0) {
            return data['attendance_report_details'];
          }
          else {
            return '';
          }
        }),
      )
  }

  getAssessmentActivityList(organization_id, player_id, start_count) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('start_count', start_count);

    const getPlayerAssessmentActivityListUrl = AppComponent.API_URL + 'player/GetPlayerAssessmentActivityList';
    return this.http_client.post(getPlayerAssessmentActivityListUrl, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPlayerAssessmentActivityDetails(organization_id, player_id, start_count, activity_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('start_count', start_count);
    params = params.append('activity_id', activity_id);

    const getPlayerAssessmentActivityListUrl = AppComponent.API_URL + 'player/GetPlayerAssessmentActivityDetails';
    return this.http_client.post(getPlayerAssessmentActivityListUrl, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getReportList(player_id, organization_id, performance_set_assessment_id, activity_id) {
    const params = new HttpParams().set('organization_id', organization_id)
      .set('filter[player_id]', player_id)
      .set('performance_set_assessment_id', performance_set_assessment_id)
      .set('activity_id', activity_id);
    // const url=AppComponent.API_URL+'report/GetReportPublishList';
    const url = AppComponent.API_URL + 'report/GetActivityAssessmentReportList';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }


  getPlayerReport(academy_id, performance_set_criteria_setting_id, performance_set_assessment_id, player_id) {

    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    performance_set_criteria_setting_id.forEach((element, i) => {
      params = params.append('performance_set_criteria_setting_id[' + i + ']', element);
    });
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);

    if (player_id != undefined || player_id != null) {
      params = params.append('filter[player_id]', player_id);
    }

    // const url=AppComponent.API_URL+'report/GetPlayerReport';
    const url = AppComponent.API_URL + 'report/GetPlayerAssessmentReport';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status'] == 200) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAttendanceConfiguration(organization_id) {

    const params = new HttpParams().set('organization_id', organization_id);

    const local_url = AppComponent.API_URL + 'entitlement/GetAttendanceConfiguration';
    return this.http_client.post(local_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  updateReRegisterPlayerFeesDetails(organization_id, player_id, academy_center_id, plan_id, frequency,
    payment_type, invoice_amount, student_fee_id, payment_due_date, existing_plan_id, customer_id, training_start_date
  ) {

    //   subscription_expiry_date,days,subscription_reason,subscription_resumes_from_date,
    // renewal_criteria,no_of_sessions
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('plan_id', plan_id);
    params = params.append('frequency', frequency);
    params = params.append('payment_type', payment_type);
    params = params.append('invoice_amount', invoice_amount);
    params = params.append('student_fee_id', student_fee_id);
    params = params.append('existing_plan_id', existing_plan_id);
    params = params.append('customer_id', customer_id);
    params = params.append('payment_due_date', this.dateFormater(payment_due_date));
    params = params.append('training_start_date', this.dateFormater(training_start_date));


    const local_player_fee_setup_url = AppComponent.API_URL + 'payment/UpdatePlayerFeesDetails';
    return this.http_client.post(local_player_fee_setup_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  addFeePlanWhileReRegister(organization_id, academy_center_id, Player_id, customer_id, plan_id, frequency, payment_type, invoice_amount,
    payment_due_date, training_start_date) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', Player_id);
    params = params.append('customer_id', customer_id);
    params = params.append('from_reregistration_in_same_center', '1');


    if (payment_type == "Recurring" || payment_type == "Installments" || payment_type == "Free") {
      params = params.append('payment_type', payment_type);
      params = params.append('plan_id', plan_id);
      params = params.append('invoice_amount', invoice_amount);

      if (payment_type == "Recurring") {
        params = params.append('frequency', frequency);
        params = params.append('payment_due_date', this.dateFormater(payment_due_date));
        params = params.append('training_start_date', this.dateFormater(training_start_date));
      }
      if (payment_type == "Installments") {
        params = params.append('frequency', 'NA');
        params = params.append('payment_due_date', this.dateFormater(payment_due_date));
        params = params.append('training_start_date', this.dateFormater(training_start_date));
      }
      if (payment_type == "Free") {
        params = params.append('frequency', 'NA');
        var current_date = new Date();
        params = params.append('payment_due_date', this.dateFormater(current_date));
        params = params.append('training_start_date', this.dateFormater(current_date));
      }
    }
    if (payment_type == "Onetime") {
      params = params.append('oneTime_payment_type[0]', payment_type);
      params = params.append('oneTime_plan_id[0]', plan_id);
      params = params.append('onetime_invoice_amount[0]', invoice_amount);
      params = params.append('oneTime_payment_due_date[0]', this.dateFormater(payment_due_date));
      params = params.append('oneTime_training_start_date[0]', this.dateFormater(training_start_date));
      params = params.append('oneTime_frequency[0]', frequency);
    }
    if (payment_type == "Subscription") {
      params = params.append('subsription_payment_type[0]', payment_type);
      params = params.append('subsription_plan_id[0]', plan_id);
      params = params.append('subsription_invoice_amount[0]', invoice_amount);
      params = params.append('subsription_payment_due_date[0]', this.dateFormater(payment_due_date));
      params = params.append('subsription_training_start_date[0]', this.dateFormater(training_start_date));
      params = params.append('subsription_frequency[0]', frequency);
    }
    const url = AppComponent.API_URL + 'player/AddPlayerFeesSetup';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );

  }

  playerCompetitionTrackerFacetNavigation(player_id, from_date, to_date, organization_id) {
    let params = new HttpParams();
    params = params.append('player_id', player_id);
    if (from_date != undefined) {
      params = params.append('filters[from_date]', this.dateFormater(from_date));
    }
    if (to_date != undefined) {
      params = params.append('filters[to_date]', this.dateFormater(to_date));
    }
    params = params.append('organization_id', organization_id);
    const url = AppComponent.API_URL + 'player/PlayerProfileCompetitionTrackerFacetNavigation';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data.length != 0) {
            return data;
          }
          else {
            return '';
          }

        })
      )
  }
  getPlayerIdenticardDetails(organization_id, academy_center_id, player_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('academy_center_id', academy_center_id);
    params = params.append('player_id', player_id);

    const url = AppComponent.API_URL + 'player/GetPlayerIdenticardDetails';

    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.card_details=data['data'];
          if (this.card_details.length != 0) {
            return this.card_details;
          }
          else {
            return '';
          }
        })
      )

  }

  getNotification(organization_id, template_id, player_id, academy_center_id, notification_mode, subject, customer_id, body) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('template_id', template_id);
    
    if (player_id != undefined) {
      player_id.forEach((element, i) => {
        params = params.append('player_id[' + i + ']', element);
      });
    }
  
    if (academy_center_id != undefined) {
      academy_center_id.forEach((element, i) => {
        params = params.append('academy_center_id[' + i + ']', element);
      });
    }
  
    if (notification_mode != undefined) {
      notification_mode.forEach((element, i) => {
        params = params.append('notification_mode[' + i + ']', element);
      });
    }
  
    if (subject != undefined) {
      subject.forEach((element, i) => {
        params = params.append('subject[' + i + ']', element);
      });
    }
  
    params = params.append('customer_id', customer_id);
    params = params.append('body', body);
  
    const url = AppComponent.API_URL + 'mobile/SendNotification';
  
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.notification_details = data['data'];
          if (this.notification_details.length != 0) {
            return this.card_details;
          } else {
            return '';
          }
        })
      );
  }
   
  getPrivilagePlayerDetails(organization_id,UIN) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('UIN', UIN);
    const url = AppComponent.API_URL + 'player/GetPrivilagePlayerDetails';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.card_details=data;
          if (this.card_details.length != 0) {
            return this.card_details;
           }
           else {
             return '';
           }
        })
      )
  }

  addInIsPrivilegeUserTable(organization_id,player_id,academy_player_id,privilege_category,uin_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    params = params.append('academy_player_id', academy_player_id);
    params = params.append('privilege_category', privilege_category);
    params = params.append('uin_id', uin_id);

    const url = AppComponent.API_URL + 'player/AddInIsPrivilegeUserTable';
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.user_details=data;
          if (this.user_details.length != 0) {
            return this.user_details;
           }
           else {
             return '';
           }
        })
      )
  }

}